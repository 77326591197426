import { ArrowForwardIos, Today } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const AvatarCarousel = ({ avatarUrls }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const AVATAR_SIZE = isMobile ? 60 : 60;
  const OVERLAP = 10;
  const SLOT_WIDTH = AVATAR_SIZE - OVERLAP; // 30px effective horizontal shift per slot
  const TRANSITION_DURATION = 400; // in ms
  const INTERVAL_DURATION = 1600; // in ms
  const TOTAL_SLOTS = 5; // visible avatars

  // Each avatar has a slot (0 = leftmost, 4 = rightmost) and a status:
  //   'normal' means scale 1,
  //   'entering' means it starts at scale 0 then transitions to normal,
  //   'leaving' means it transitions from scale 1 to 0.
  const [avatars, setAvatars] = useState([]);
  const nextIndexRef = useRef(TOTAL_SLOTS);
  const nextIdRef = useRef(TOTAL_SLOTS);

  // Initialize avatars in slots 0 to 4.
  useEffect(() => {
    const initialAvatars = [];
    for (let i = 0; i < TOTAL_SLOTS; i++) {
      initialAvatars.push({
        id: i,
        url: avatarUrls[i % avatarUrls.length],
        slot: i,
        status: "normal",
      });
    }
    setAvatars(initialAvatars);
  }, [avatarUrls]);

  // Cycle every INTERVAL_DURATION milliseconds.
  useEffect(() => {
    const interval = setInterval(() => {
      cycleAvatars();
    }, INTERVAL_DURATION);
    return () => clearInterval(interval);
  }, [avatarUrls]);

  const cycleAvatars = () => {
    // Step 1: Update each avatar’s slot by decrementing it.
    // The avatar at slot 0 becomes “leaving.”
    setAvatars((prev) =>
      prev.map((avatar) => ({
        ...avatar,
        slot: avatar.slot - 1,
        status: avatar.slot === 0 ? "leaving" : avatar.status,
      }))
    );

    // Step 2: Add a new avatar at the rightmost slot (slot index TOTAL_SLOTS - 1)
    // with an "entering" status (initial scale 0).
    setAvatars((prev) => [
      ...prev,
      {
        id: nextIdRef.current,
        url: avatarUrls[nextIndexRef.current % avatarUrls.length],
        slot: TOTAL_SLOTS - 1,
        status: "entering",
      },
    ]);
    nextIdRef.current++;
    nextIndexRef.current++;

    // Step 3: After a brief delay, update new avatars from "entering" to "normal"
    // so they animate their scale from 0 to 1.
    setTimeout(() => {
      setAvatars((prev) =>
        prev.map((avatar) =>
          avatar.status === "entering"
            ? { ...avatar, status: "normal" }
            : avatar
        )
      );
    }, 50);

    // Step 4: After the transition duration, remove any avatar that has moved off (slot < 0).
    setTimeout(() => {
      setAvatars((prev) => prev.filter((avatar) => avatar.slot >= 0));
    }, TRANSITION_DURATION);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: AVATAR_SIZE + (TOTAL_SLOTS - 1) * SLOT_WIDTH,
        height: AVATAR_SIZE,
      }}
    >
      {avatars.map((avatar) => {
        // Compute the horizontal position based on the slot.
        const translateX = avatar.slot * SLOT_WIDTH;
        // For "normal" avatars, scale is 1.
        // For "entering" (and still "leaving") avatars, we want scale 0.
        const scale = avatar.status === "normal" ? 1 : 0;
        return (
          <Avatar
            key={avatar.id}
            src={avatar.url}
            sx={{
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
              position: "absolute",
              // Animate both translation and scale.
              transform: `translateX(${translateX}px) scale(${scale})`,
              transition: `transform ${TRANSITION_DURATION}ms ease-in-out`,
              border: "2px solid white",
              // Ensure proper stacking (later slots appear on top).
              zIndex: avatar.slot + TOTAL_SLOTS,
            }}
          />
        );
      })}
    </Box>
  );
};

const ScheduleCTA = ({ avatarUrls }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/get-started");
  };

  return (
    <Box
      elevation={0}
      sx={{
        px: 2,
        py: 4,
        my: 2,
        mx: "auto",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        bgcolor: "primary.dark",
      }}
    >
      <Card
        elevation={0}
        sx={{
          p: 3,
          my: 2,
          maxWidth: "600px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            alignItems: "flex-start",
            gap: 1,
            mb: 1,
          }}
        >
          <Today sx={{ width: "48px", height: "48px" }} color="primary" />
          <Typography variant="h3" component="h2">
            Schedule Online
          </Typography>
        </Box>
        <Typography
          variant="body1"
          fontSize="20px"
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          In just a few minutes, you can book a visit with one of our wonderful
          helpers to tackle your to-do list. Enjoy peace-of-mind by scheduling
          online today.
        </Typography>

        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 3,
          }}
        >
          <AvatarCarousel avatarUrls={avatarUrls} />
          <Button
            variant="contained"
            size="large"
            sx={{
              textTransform: "none",
              fontSize: "16px",
              width: { xs: "100%", sm: "unset" },
              height: "56px",
            }}
            onClick={handleClick}
            endIcon={<ArrowForwardIos />}
          >
            Book a visit
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ScheduleCTA;
