import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const PingPongScroll = ({ speed = 1, children }) => {
  const [direction, setDirection] = useState("left"); // current scroll direction
  const containerRef = useRef(null); // reference to the scrolling container

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let frameId;
    const scrollStep = () => {
      if (direction === "left") {
        container.scrollLeft += speed;
        if (
          container.scrollLeft + container.clientWidth >=
          container.scrollWidth
        ) {
          setDirection("right");
        }
      } else {
        container.scrollLeft -= speed;
        if (container.scrollLeft <= 0) {
          setDirection("left");
        }
      }
      frameId = requestAnimationFrame(scrollStep);
    };

    frameId = requestAnimationFrame(scrollStep);

    return () => cancelAnimationFrame(frameId);
  }, [direction]);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        overflowX: "hidden",
        width: "100vw", // Full-screen width
        whiteSpace: "nowrap", // Prevents children from wrapping
        position: "relative",
        pointerEvents: "none",
      }}
    >
      {children}
    </Box>
  );
};

export default PingPongScroll;
