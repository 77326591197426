import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  where,
} from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { db } from "../firebase";

const CallsContext = createContext();
const PAGE_SIZE = 5;

const now = new Date(); // Current time

export const testLogs = [
  {
    id: "1",
    hasBeenRead: false,
    call: {
      caller: { displayName: null, phone: "123-456-7890" },
      receiver: { displayName: "Bob Smith", phone: "987-654-3210" },
      direction: "incoming",
      statusHistory: [
        {
          status: "ringing",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 10000)), // 5 seconds ago
          to: "Bob Smith",
        },
        {
          status: "answered",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 2000)), // 3 seconds ago
          to: "Bob Smith",
        },
        {
          status: "ringing",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 10000)), // 7 seconds ago
          to: "3607081269",
        },
        {
          status: "no-answer",
          timestamp: Timestamp.fromDate(new Date(now.getTime() - 4000)), // 4 seconds ago
          to: "3607081269",
        },
      ],
      madeContact: true,
      duration: 120,
    },
    voicemail: {
      recordingUrl: "https://example.com/audio1.mp3",
      transcriptionText: "Hello, please call me back.",
    },
  },
  {
    id: "2",
    hasBeenRead: true,
    call: {
      caller: { displayName: "Charlie Liken", phone: "555-555-5555" },
      receiver: { displayName: "Dana", phone: "444-444-4444" },
      direction: "outgoing",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Dana" },
        { status: "completed", timestamp: Timestamp.now(), to: "Dana" },
      ],
      madeContact: false,
      duration: 0,
    },
    voicemail: null,
  },
  {
    id: "3",
    hasBeenRead: true,
    call: {
      caller: { displayName: "Eve", phone: "111-222-3333" },
      receiver: { displayName: "Frank", phone: "333-222-1111" },
      direction: "incoming",
      statusHistory: [
        { status: "missed", timestamp: Timestamp.now(), to: "Frank" },
      ],
      madeContact: false,
      duration: 0,
    },
    voicemail: {
      recordingUrl: "https://example.com/audio3.mp3",
      transcriptionText: "I missed your call.",
    },
  },
  {
    id: "4",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Grace", phone: "222-333-4444" },
      receiver: { displayName: "Heidi", phone: "444-333-2222" },
      direction: "incoming",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Heidi" },
        { status: "answered", timestamp: Timestamp.now(), to: "Heidi" },
      ],
      madeContact: true,
      duration: 180,
    },
    voicemail: null,
  },
  {
    id: "5",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Ivan", phone: "777-888-9999" },
      receiver: { displayName: "Judy", phone: "999-888-7777" },
      direction: "outgoing",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Judy" },
        { status: "answered", timestamp: Timestamp.now(), to: "Judy" },
      ],
      madeContact: true,
      duration: 90,
    },
    voicemail: null,
  },
  {
    id: "6",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Kevin", phone: "123-123-1234" },
      receiver: { displayName: "Laura", phone: "432-432-4321" },
      direction: "incoming",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Laura" },
        { status: "answered", timestamp: Timestamp.now(), to: "Laura" },
      ],
      madeContact: true,
      duration: 150,
    },
    voicemail: {
      recordingUrl: "https://example.com/audio6.mp3",
      transcriptionText: "Please call me back asap.",
    },
  },
  {
    id: "7",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Mallory", phone: "222-555-6666" },
      receiver: { displayName: "Oscar", phone: "666-555-2222" },
      direction: "incoming",
      statusHistory: [
        { status: "missed", timestamp: Timestamp.now(), to: "Oscar" },
      ],
      madeContact: false,
      duration: 0,
    },
    voicemail: null,
  },
  {
    id: "8",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Peggy", phone: "333-444-5555" },
      receiver: { displayName: "Trent", phone: "555-444-3333" },
      direction: "incoming",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Trent" },
        { status: "answered", timestamp: Timestamp.now(), to: "Trent" },
      ],
      madeContact: true,
      duration: 200,
    },
    voicemail: {
      recordingUrl: "https://example.com/audio8.mp3",
      transcriptionText: "I will call back later.",
    },
  },
  {
    id: "9",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Victor", phone: "444-555-6666" },
      receiver: { displayName: "Wendy", phone: "666-555-4444" },
      direction: "outgoing",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Wendy" },
        { status: "no-answer", timestamp: Timestamp.now(), to: "Wendy" },
      ],
      madeContact: false,
      duration: 0,
    },
    voicemail: null,
  },
  {
    id: "10",
    hasBeenRead: false,
    call: {
      caller: { displayName: "Xavier", phone: "777-888-0000" },
      receiver: { displayName: "Yvonne", phone: "000-888-7777" },
      direction: "incoming",
      statusHistory: [
        { status: "ringing", timestamp: Timestamp.now(), to: "Yvonne" },
        { status: "answered", timestamp: Timestamp.now(), to: "Yvonne" },
      ],
      madeContact: true,
      duration: 110,
    },
    voicemail: {
      recordingUrl: "https://example.com/audio10.mp3",
      transcriptionText: "Call me back when you can.",
    },
  },
];

export const CallsProvider = ({ children }) => {
  const [callsData, setCallsData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  // Initial page load with onSnapshot
  useEffect(() => {
    const q = query(
      collection(db, "logs"),
      where("logType", "==", "call"),
      orderBy("created", "desc"),
      limit(PAGE_SIZE)
    );
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot.empty) {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCallsData(docs);
          setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
          setHasMore(snapshot.docs.length === PAGE_SIZE);
        } else {
          setCallsData([]);
          setHasMore(false);
        }
      },
      (error) => {
        console.error("Error fetching initial logs: ", error);
      }
    );
    return () => unsubscribe();
  }, []);

  // Fetch additional pages when scrolling
  const fetchMore = async () => {
    if (!hasMore || loading || !lastDoc) return;
    setLoading(true);
    try {
      const q = query(
        collection(db, "logs"),
        where("logType", "==", "call"),
        orderBy("created", "desc"),
        startAfter(lastDoc),
        limit(PAGE_SIZE)
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Merge new docs while avoiding duplicates
        setCallsData((prev) => {
          const existingIds = new Set(prev.map((item) => item.id));
          const newItems = docs.filter((item) => !existingIds.has(item.id));
          return [...prev, ...newItems];
        });
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === PAGE_SIZE);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more logs: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CallsContext.Provider value={{ callsData, fetchMore, hasMore, loading }}>
      {children}
    </CallsContext.Provider>
  );
};

export const useCalls = () => React.useContext(CallsContext);
