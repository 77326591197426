import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Photo1 from "../../../assets/Alex_cutout.png";

import inquiryPng from "../../../assets/clipboard-pencil.png";
import calendarPng from "../../../assets/calendar-view.png";
import locationPng from "../../../assets/find-location.png";
import ContactDetails from "../../contact/ContactDetails";

function ContactSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  // Define the data array
  // Define the data array with icons for each item
  const navigationItems = [
    {
      title: "Inquiry",
      path: "/inquiry",
      description: "Submit an online inquiry",
      icon: inquiryPng,
    },
    {
      title: "Schedule",
      path: "/dashboard",
      description: "Schedule a visit online",
      icon: calendarPng,
    },
    {
      title: "Locations",
      path: "/locations",
      description: "See our service locations",
      icon: locationPng,
    },
    // {
    //   title: "FAQ",
    //   path: "/faq",
    //   description: "Frequently asked questions",
    //   icon: faqPng,
    // },
  ];

  return (
    <Box sx={{ pt: { xs: 2, sm: 5 }, mx: 2 }}>
      <Box
        sx={{
          mb: 2,
          gap: { xs: 1, sm: 2 },
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          boxSizing: "border-box",
          maxWidth: "1200px",
          mx: "auto",
        }}
      >
        {navigationItems.map((item, index) => (
          <Box sx={{ flexGrow: 1, boxSizing: "border-box" }} key={index}>
            <Card
              sx={{
                boxShadow:
                  "0px 4px 10px rgba(0, 0, 0, 0.1), 0px 8px 15px rgba(0, 0, 0, 0.15)",
              }}
            >
              <CardActionArea onClick={() => navigate(item.path)}>
                <Box display="flex" alignItems="center">
                  {/* Icon on the left */}
                  <CardMedia
                    component="img"
                    image={item.icon}
                    alt={`${item.title} icon`}
                    sx={{
                      width: { xs: 40, sm: 60 },
                      height: { xs: 40, sm: 60 },
                      m: { xs: 2, sm: 2 },
                    }}
                  />

                  {/* Content in the center */}
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>

                  {/* Arrow on the far right */}
                  <Box sx={{ mr: 2 }}>
                    <ArrowForwardIos />
                  </Box>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          maxWidth: "900px",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        {!isMobile && (
          <CardMedia
            component="img"
            image={Photo1}
            alt={`Alex Rodriguez, owner`}
            sx={{ width: 450, height: "auto", ml: "auto", mr: 4, mt: 2 }}
          />
        )}
        <Box sx={{ mt: { xs: 2, sm: 2 }, mb: { xs: 2, sm: 0 } }}>
          <Typography
            variant="h3"
            gutterBottom
            align={isMobile ? "center" : "left"}
          >
            Contact Us
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            {isMobile && (
              <CardMedia
                component="img"
                image={Photo1}
                alt={`Alex Rodriguez, owner`}
                sx={{ width: 150, height: 190, ml: "auto" }}
              />
            )}
            <Box>
              <Typography variant="body1">
                "I am here to help! Whether you want to ask a question, get
                assistance, or give feedback, I would personally love to hear
                from you."
              </Typography>
              <Typography
                variant="caption"
                fontSize={"0.875rem"}
                color={"text.secondary"}
                sx={{ fontStyle: "italic" }}
              >
                - Alex Rodriguez, Owner
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mt: { xs: 0, sm: 2 }, mb: 2 }} />
          <ContactDetails />
        </Box>
      </Box>
    </Box>
  );
}

export default ContactSection;
