import { ArrowBackIosNewRounded, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import Logo from "../../svgs/Logo";

const ResponsiveDialog = ({
  showDefaultHeader = true,
  title,
  open,
  onClose,
  children,
  dialogActions = false,
  width = "800px",
  fullHeight = "auto",
  maxHeight = "auto",
  anchor = "bottom",
  desktopAnchor = null,
  showAction = true,
  showBackdrop = true,
  showLogo = false,
  zIndex = false,
  backgroundCloses = true,
  closeConfirmationDialog = null, // New prop
  modalType = null,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { openDrawer } = useDrawer() || {}; // Use the drawer provider

  console.log("showDefaultHeader: ", showDefaultHeader);

  const borderRadius = anchor === "bottom" ? "15px" : "0px";
  const mobileHeight = anchor === "bottom" ? "98vh" : "100vh";

  const handleClose = (event, reason) => {
    console.log(
      "handleClose - closeConfirmationDialog: ",
      closeConfirmationDialog
    );

    if (closeConfirmationDialog) {
      openDrawer(closeConfirmationDialog, {
        showDefaultHeader: false,
        modalType: "dialog",
        onConfirm: () => {
          onClose(); // Proceed with the actual dialog close
        },
        // onCancel: () => closeDrawer(),  // Simply close the confirmation
      });
      return;
    }

    if (reason !== "backdropClick" || backgroundCloses) {
      onClose();
    }
  };

  const renderDrawerHeader = (title) => (
    <Box
      sx={{
        position: "relative", // Added to position children absolutely within this Box.
        display: "flex",
        alignItems: "center", // Vertically aligns items in the center.
        padding: 2,
        justifyContent: showAction || showLogo ? "space-between" : "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        height: "80px",
        maxHeight: "80px",
        minHeight: "80px",
        boxSizing: "border-box",
      }}
    >
      {showAction && (
        <IconButton onClick={handleClose}>
          {!isMobile && <Close />}
          {isMobile && anchor === "bottom" && <Close />}
          {isMobile && anchor === "right" && <ArrowBackIosNewRounded />}
        </IconButton>
      )}

      {showLogo && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: "2000",
          }}
        >
          <Box width={150}>
            <Logo color="rgba(0, 0, 0, 0.87)" />
          </Box>
        </Box>
      )}

      {/* Title container to help center the title text */}
      <Box
        sx={{
          mx: showLogo ? 0 : 1, // Adjust margin based on logo presence
          flexGrow: showLogo ? 1 : 0, // If logo is present, allow title to grow
          display: "flex",
          justifyContent: showLogo ? "flex-end" : "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>

      {showAction && (
        <IconButton style={{ visibility: "hidden", pointerEvents: "none" }}>
          <Close />
        </IconButton>
      )}

      {/* Make puller bar */}
      {isMobile && anchor === "bottom" && showAction && (
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: "50%",
            transform: "translateX(-50%)",
            width: 50,
            height: 5,
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 2.5,
          }}
        />
      )}
    </Box>
  );

  const renderDialog = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      hideBackdrop={!showBackdrop}
      PaperProps={{
        sx: {
          zIndex: zIndex && zIndex,
          position: "relative", // Set position to relative
          borderRadius: "15px",
          width: width,
          maxWidth: width,
          maxHeight: `min(calc(100% - 64px), ${maxHeight})`,
        },
      }}
    >
      {/* Dialog content */}
      {showDefaultHeader && renderDrawerHeader(title)}
      <Divider />

      {children}
      {dialogActions && (
        <>
          <Divider />
          <DialogActions>{dialogActions}</DialogActions>
        </>
      )}
    </Dialog>
  );

  if (modalType === "dialog") {
    return renderDialog();
  }

  return isMobile ? (
    <SwipeableDrawer
      anchor={anchor}
      variant={showAction ? "temporary" : "permanent"}
      open={open}
      onClose={handleClose}
      onOpen={() => {}}
      disableSwipeToOpen={true}
      PaperProps={{
        sx: {
          className: "hideScrollbar",
          borderTopLeftRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-left corner
          borderTopRightRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-right corner
          maxHeight: mobileHeight,
          height: fullHeight,
          width: "100%",
          zIndex: zIndex && zIndex,
          boxShadow:
            "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
          // boxSizing: "border-box",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor:
              !isMobile && !showBackdrop ? "transparent" : undefined,
          },
        },
      }}
    >
      {/* SwipeableDrawer content */}
      {showDefaultHeader && renderDrawerHeader(title)}
      {/* {open && children} */}
      {children}
      {dialogActions && (
        <DialogActions
          sx={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
            justifyContent: "center",
          }}
        >
          {dialogActions}
        </DialogActions>
      )}
    </SwipeableDrawer>
  ) : (
    <>
      {desktopAnchor && (
        <SwipeableDrawer
          disableSwipeToOpen={true}
          open={open}
          anchor={desktopAnchor}
          onClose={handleClose}
          slotProps={{
            backdrop: {
              style: {
                backgroundColor: showBackdrop ? undefined : "transparent",
              },
            },
          }}
          PaperProps={{
            sx: {
              width: width,
              maxWidth: width,
              zIndex: zIndex && zIndex,
              borderTopLeftRadius: { xs: "15px", sm: "0px" }, // Rounded top-left corner
              borderTopRightRadius: { xs: "15px", sm: "0px" }, // Rounded top-right corner
              maxHeight: `min(calc(100% - 64px), ${maxHeight})`,
            },
          }}
        >
          {showDefaultHeader && renderDrawerHeader(title)}
          {children}
          {dialogActions && (
            <>
              <Divider />
              <DialogActions
                sx={{
                  justifyContent:
                    desktopAnchor === "right" ? "flex-start" : "flex-end",
                }}
              >
                {dialogActions}
              </DialogActions>
            </>
          )}
        </SwipeableDrawer>
      )}
      {desktopAnchor === null && renderDialog()}
    </>
  );
};

export default ResponsiveDialog;
