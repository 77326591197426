import { Box } from "@mui/material";
import React from "react";

import checkListPng from "../../../assets/check_list_png.png";
import loveChatPng from "../../../assets/love-chat.png";
import piggyBankPng from "../../../assets/piggy_bank_png.png";
import thumbsUpPng from "../../../assets/thumbs_up_png.png";
import BasicCard from "../../../components/cards/BasicCard";

const contentSections = [
  {
    image: checkListPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(150,100%,70%,0.75)",
    title: "Track Your To-Do’s",
    description: `Over the weeks, our members keep track of the tasks they don’t want to do, 
      can’t do, or want an extra hand with. When you're ready for 1-2 hours of help, 
      schedule over the phone or online to mark your to-do list complete.`,
  },
  {
    image: thumbsUpPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(191,100%,70%,0.75)",
    title: "Many Tasks, One Visit",
    description: `Our helpers are loved for their versatility! Within a single visit, you can fix a problem 
    on your computer, move heavy garage boxes, scrub your cabinets, and weed your garden, 
    all while getting to know your incredible helper.`,
  },
  {
    image: piggyBankPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(210,100%,70%,0.75)",
    // title: "Help For Under $80",
    title: "Help For $25/hr",
    // There's no monthly fees and you can
    description: `Enjoy help on demand as low as $25/hr on our Premium plan. It's always a flat rate no matter what the task.
    Our members book several hours a month on average, but 
    you can easily book more or less depending on your budget.`,
  },
  {
    image: loveChatPng,
    shadow:
      "0 8px 12px rgba(0, 0, 0, 0.2), 0 10px 20px hsla(350, 100.00%, 70.00%, 0.75)",
    title: "Loved by Hundreds",
    description: `Our members love our service so much that they write raving reviews after almost every visit. 
    They love our hard work, efficiency, and kindness - We believe you will too!`,
  },
];

const BenefitsSection = () => {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 4 },
        mx: "auto",
        textAlign: "left",
        maxWidth: "700px",
      }}
    >
      {contentSections.map((section, index) => (
        <BasicCard
          key={index}
          section={section}
          index={index}
          pageName={"/home"}
        />
      ))}
    </Box>
  );
};

export default BenefitsSection;
