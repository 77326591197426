import { Box, Fade, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSession } from "../../../providers/SessionProvider";
import { MEMBER_COUNT } from "../../../services/variableServices";
import IntakeForm from "./IntakeForm";
import PricingCards from "./PricingCards";

const PricingSectionV2 = () => {
  const { locationData } = useSession();
  const zipCode = locationData?.postal || null;
  const city = locationData?.city || null;
  const theme = useTheme();

  const [leadCaptured, setLeadCaptured] = useState(false); //change back to false

  useEffect(() => {
    const leadCaptured = localStorage.getItem("leadCaptured");

    // Assuming the intention is to check if leadCaptured is "true"
    if (leadCaptured === "true") {
      setLeadCaptured(true); // Assuming you have a state setter function named setLeadCaptured
    }
  }, []);

  return (
    <Box sx={{ position: "relative", width: "auto" }}>
      {/* <CredibilityGrid /> */}

      {!leadCaptured ? (
        <Fade in={!leadCaptured} timeout={500}>
          <Box>
            <IntakeForm setLeadCaptured={setLeadCaptured} />
          </Box>
        </Fade>
      ) : (
        <Fade in={leadCaptured} timeout={500}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
              pt: { xs: 4, sm: 10 },
              pb: { xs: 4, sm: 4 },
              px: 1,
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              variant="h3"
              sx={{
                display: "inline-block",
                textAlign: "left",
              }}
            >
              <Box
                component="span"
                sx={{
                  display: { xs: "block", sm: "inline" }, // Force a break on mobile
                }}
              >
                Choose
              </Box>{" "}
              Your Plan
            </Typography>

            <Typography align="left" sx={{ maxWidth: "680px", mt: 2 }}>
              We offer transparent and simple pricing, that's flexible for your
              budget. Join over {Math.floor(MEMBER_COUNT / 10) * 10}+{" "}
              <Link
                component="span"
                to="/reviews"
                style={{
                  fontWeight: "600",
                  color: theme.palette.primary.main,
                }}
              >
                happy members
              </Link>{" "}
              who count on us for their household help. Consistent, reliable,
              and here for you.
            </Typography>
            <div style={{ height: "24px" }} />

            <PricingCards dense={true} zipCode={zipCode} city={city} />
            <Typography variant="body1"></Typography>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default PricingSectionV2;
