import React, { Suspense, lazy } from "react";
import DrawerContainer from "../../components/drawers/DrawerContainer";
import { DrawerProvider } from "../../providers/DrawerProvider";
import { MemberProvider } from "../../providers/MemberProvider";
import LoadingUser from "../../components/LoadingUser";
import {ScheduleVisitProvider} from "../../providers/ScheduleVisitProvider";
import HelpersProvider from "../../providers/HelpersProvider";

const MemberDashboard = lazy(() =>
  import("./MemberDashboard")
);

const LazyMemberDashboard = () => {
  return (
    <Suspense fallback={<LoadingUser />}>
      <MemberProvider>
        <HelpersProvider>
          <DrawerProvider>
            <ScheduleVisitProvider>
              <DrawerContainer />
              <MemberDashboard />
            </ScheduleVisitProvider>
          </DrawerProvider>
        </HelpersProvider>
      </MemberProvider>
    </Suspense>
  );
};

export default LazyMemberDashboard;
