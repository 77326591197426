import React, { Suspense, lazy } from "react";
import CallInterface from "../../components/calls/CallInterface";
import DrawerContainer from "../../components/drawers/DrawerContainer";
import { CallsProvider } from "../../providers/CallsProvider";
import { CRMProvider } from "../../providers/CRMProvider";
import { DrawerProvider } from "../../providers/DrawerProvider";
import EmployeeProvider from "../../providers/EmployeeProvider";
import { MessagesProvider } from "../../providers/MessagesProvider";
import { TwilioProvider } from "../../providers/TwilioProvider";
import LoadingUser from "../../components/LoadingUser";

const AdminDashboard = lazy(() => import("./AdminDashboard"));

const LazyAdminDashboard = () => {
  return (
    <Suspense fallback={<LoadingUser />}>
      <TwilioProvider>
        <EmployeeProvider>
          <CRMProvider>
            <DrawerProvider>
              <CallsProvider>
                <MessagesProvider>
                  <CallInterface />
                  <DrawerContainer />
                  <AdminDashboard />
                </MessagesProvider>
              </CallsProvider>
            </DrawerProvider>
          </CRMProvider>
        </EmployeeProvider>
      </TwilioProvider>
    </Suspense>
  );
};

export default LazyAdminDashboard;
