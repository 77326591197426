import {
  Avatar,
  AvatarGroup,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import InfiniteScrollAvatars from "../components/popup/InfiniteScrollAvatars";
import VisitReviewList from "../components/reviews/VisitReviewList";

const AdCopyPage = () => {
  const theme = useTheme(); // hook to get current theme
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageUrls2 = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FPsHA5ZvGMXXJLCljzhKK6Iv6xXR2?alt=media&token=d4073e7e-2a7c-4d6a-a524-19e8efedac9f", //alex
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FVpWaaQKGZ1YqJ6WuRi12SUQUC992_2%202.jpg?alt=media&token=2bae30ef-8962-4d80-b67e-19c5f16241e4", //brandon
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FdXA5IwQcnUe0HyNvSKXXqWPb6ug2?alt=media&token=d063516b-e919-474b-9959-368245a60f97", // caz
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b", // carlo
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02", // eli
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F8jBCWIZIhZRMaOqwKJGsvsiXmkt1?alt=media&token=f4202b0e-082a-4375-b729-119c4be7e59c", // addie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2F848A5462.jpg?alt=media&token=3c485bdb-6418-473d-b684-799c77a24df3", // kylie
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2Fmichael-h.jpg?alt=media&token=41177cdd-2dc5-4dfb-99a0-8dc42eca63b5", // michael
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=a76cc0e5-a030-4e07-a303-152e3906b77b", // zach
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FlCfkMpZc2AYVfEEHcqfGGjPxSif2?alt=media&token=8cfd839a-addd-4ef3-a8d2-9daf759b5ce0", // evan
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FHsgq7Q0Zlih6wBwpz8dXUZe1lPa2.jpeg?alt=media&token=c882b656-d083-4f4c-b294-d4ec21222d91", // tyler
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FvR7PceHW1tOQSF8nnlZM8K6GXoB3?alt=media&token=070cca76-468b-4c3f-831f-a228fb3b1843", // john
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FhZJwf6mbMxYO0dy2x0jS4dbTYgB2.jpeg?alt=media&token=d7ea17df-c10f-4bba-a580-41bae8a5b19e", // ethan
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FQCDplEfeicOXPMNLanUl7EgTFcS2.jpeg?alt=media&token=7dd11fca-726b-40af-bd2e-a8aae947fb85", // sam
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FaqymXn3yV2N6SA7ZGZJYk6HwT8l1?alt=media&token=8327f1d5-c30c-45a5-b836-4c3e4d2079af", // adam
    // "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FSDWtwf3thNZQIqeIYXXIxH8lBLq2?alt=media&token=2f5786e8-1906-4a53-9776-16c6360afb8b", // jack
  ];

  const imageUrls = [
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FPsHA5ZvGMXXJLCljzhKK6Iv6xXR2?alt=media&token=d4073e7e-2a7c-4d6a-a524-19e8efedac9f", //alex
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FriBLKN4hnYd13VtcJHQsWnJikKc2?alt=media&token=a76cc0e5-a030-4e07-a303-152e3906b77b", // zach
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FwNoANWJjh1Unp4MtBex4wfmBexv1?alt=media&token=53546ad1-1e22-4d9f-9b08-6e5ba01ccd02", // eli
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FVpWaaQKGZ1YqJ6WuRi12SUQUC992_2%202.jpg?alt=media&token=2bae30ef-8962-4d80-b67e-19c5f16241e4", //brandon
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2Fmichael-h.jpg?alt=media&token=41177cdd-2dc5-4dfb-99a0-8dc42eca63b5", // michael
    "https://firebasestorage.googleapis.com/v0/b/ourlinkedlives.appspot.com/o/avatars%2FmORSk4BgMcS9dUiU29RgWvdleUB3?alt=media&token=8654191c-f255-4ce6-883c-e6582c12fe0b", // carlo
  ];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          zIndex: 2,
          background: "white",
          border: !isMobile && "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "15px",
          boxShadow: !isMobile && "0px 6px 15px rgba(0, 0, 0, 0.3)", // Custom shadow
          maxWidth: { xs: "425px", sm: "1000px" },
          height: "800px",
          mx: "auto",
          gap: 2,
          p: { xs: 2, sm: 6 },
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        {/* <Box
          width={175}
          sx={{
            opacity: 1,
            backgroundColor: "rgb(0, 127, 153)",
            px: 2,
            pt: 1.1,
            pb: 0.8,
            mb: 1,
            borderRadius: "30px",
          }}
        >
          <Logo color="white" />
        </Box> */}
        {/* <AvatarGroup spacing={30} total={6} max={6}>
          {imageUrls.map((url, index) => (
            <Avatar
              key={index}
              alt={`Avatar ${index + 1}`}
              src={url}
              sx={{ width: 96, height: 96 }} // Customize size as needed
            />
          ))}
        </AvatarGroup> */}
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>


        <InfiniteScrollAvatars rows={2} scrollable={true} />

        </Box>

        <Typography
          zIndex={"30"}
          position={"relative"}
          variant="h2"
          sx={{ mb: 1, maxWidth: "500px" }}
          align="center"
        >
          Household Help for Seniors
        </Typography>
        <Typography
          zIndex={"30"}
          position={"relative"}
          variant="caption"
          sx={{ mb: 1, maxWidth: "400px", opacity: 0.7, fontSize: "1rem" }}
          align="center"
        >
          Licensed • Family owned • BBB Accredited
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: theme.spacing(4),
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "left",
              // height: "100%",
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h3"}
              fontWeight="700"
              gutterBottom
              align="center"
            >
              Member Reviews
              <span style={{ color: theme.palette.primary.vibrant }}></span>
            </Typography>
            <Typography
              variant="body1"
              mb={4}
              align="center"
              sx={{ maxWidth: "600px", mx: "auto" }}
            >
              Curious what our members are saying about Linked Lives? This is a
              great place to read reviews for recent visits and get inspiration
              for tasks you can get help with.
            </Typography>
          </Box>
          <VisitReviewList START_LIMIT={3} spacing={"large"} />
        </Container>
      </Box>
    </div>
  );
};

export default AdCopyPage;
