import React, { Suspense, lazy } from "react";
import DrawerContainer from "../../components/drawers/DrawerContainer";
import { DrawerProvider } from "../../providers/DrawerProvider";
import EmployeeProvider from "../../providers/EmployeeProvider";
import LoadingUser from "../../components/LoadingUser";

const EmployeeDashboard = lazy(() =>
  import("./EmployeeDashboard")

);

const LazyHelperDashboard = () => {
  return (
    <Suspense fallback={<LoadingUser />}>
      <EmployeeProvider>
        <DrawerProvider>
          <DrawerContainer />
          <EmployeeDashboard />
        </DrawerProvider>
      </EmployeeProvider>
    </Suspense>
  );
};

export default LazyHelperDashboard;
