import React, { createContext, useContext, useState } from "react";

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const [drawers, setDrawers] = useState([]);

  console.log("DrawerProvider - drawers: ", drawers);

  const openDrawer = (component, props, onClose) => {
    setDrawers((prevDrawers) => [
      ...prevDrawers,
      { component, props, onClose },
    ]);
  };

  // const handleDrawerExited = (index) => {
  //   console.log("handleDrawerExited")
  //   setDrawers((prevDrawers) => prevDrawers.filter((_, i) => i !== index));
  // };

  // Problems with race conditions...
  const handleDrawerExited = (index) => {
    console.log("CloseDrawer - drawers: ", drawers);
    console.log("handleDrawerExited - index: ", index);

    setDrawers((prev) => {
      // Set the specified index to null
      const updatedDrawers = [...prev];
      updatedDrawers[index] = null;

      // Find the highest non-null index
      const highestNonNullIndex = updatedDrawers
        .map((drawer, i) => (drawer !== null ? i : -1))
        .reduce((max, i) => Math.max(max, i), -1);

      // If the index being closed is the highest, trim nulls from the end
      if (index >= highestNonNullIndex) {
        return updatedDrawers.slice(0, highestNonNullIndex + 1);
      }

      // Otherwise, return the array with the null preserved
      return updatedDrawers;
    });
  };

  return (
    <DrawerContext.Provider value={{ drawers, openDrawer, handleDrawerExited }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => useContext(DrawerContext);
