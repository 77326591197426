import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Spinner from "./Spinner";

const LoadingUser = ({ children, loading }) => {
  // State to handle the timeout
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    // Set a timeout for 10 seconds
    const timer = setTimeout(() => {
      if (loading) {
        setIsTimeout(true);
      }
    }, 10000);

    return () => clearTimeout(timer); // Clear timeout if the component is unmounted or loading is done
  }, [loading]);

  if (isTimeout) {
    console.log("LoadingUser -- timed out");

    // If loading takes more than 5 seconds
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // This ensures it takes up the full viewport height
        bgcolor="background.paper" // Just for ensuring the background isn't the same color as the progress bar
      >
        <Alert severity="info" variant="filled" sx={{ minWidth: "300px" }}>
          <AlertTitle>Please Refresh</AlertTitle>
          <Typography>
            The request took too long! Please refresh the page.
          </Typography>
        </Alert>
      </Box>
    );
  }

  if (loading) {
    console.log("LoadingUser.js -- loading");
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh" // This ensures it takes up the full viewport height
        bgcolor="background.paper" // Just for ensuring the background isn't the same color as the progress bar
      >
        <Spinner primary="Signing you in..." secondary="Please wait" />
      </Box>
    );
  }

  console.log("LoadingUser -- done loading");
};

export default LoadingUser;
