import { Person } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import React from "react";

// const generateUniqueColor = (x, y) => {
//   const xIndex = parseInt(x, 36); // Convert to base-10 (0-35)
//   const yIndex = parseInt(y, 36); // Convert to base-10 (0-35)

//   // Generate unique properties
//   const baseHue = (xIndex * 10) % 360; // Hue derived from the first character
//   const hue = (baseHue + yIndex * 3) % 360; // Slight tweak based on the second character

//   const saturation = 70; // Fixed saturation for consistent brightness
//   const luminance = 45; // Fixed luminance for consistent visibility

//   return `hsl(${hue}, ${saturation}%, ${luminance}%)`;
// };

const generateUniqueColor = (x, y, hueOffset = 0, alpha = 1) => {
  const xIndex = parseInt(x, 36); // Convert to base-10 (0-35)
  const yIndex = parseInt(y, 36); // Convert to base-10 (0-35)

  // Generate unique properties
  const baseHue = (xIndex * 10) % 360; // Hue derived from the first character
  const hue = (baseHue + yIndex * 3 + hueOffset) % 360; // Add hueOffset for secondary color

  const saturation = 65; // Fixed saturation for consistent brightness
  const luminance = 50; // Fixed luminance for consistent visibility

  return `hsla(${hue}, ${saturation}%, ${luminance}%, ${alpha})`;
};

const getColorGradient = (initials) => {
  const color1 = generateUniqueColor(initials[0], initials[1]);
  const color2 = generateUniqueColor(initials[0], initials[1], 15); // Add 5 to the hue for color2

  return `linear-gradient(135deg, ${color1}, ${color2})`;
};

const ColorAvatar = ({ name = "Unknown", avatarUrl, size = "60px" }) => {
  const theme = useTheme();

  const getInitials = (name) => {
    if (typeof name !== "string" || name.length === 0) return "";

    const parts = name
      .replace(/\band\b/gi, "") // Remove the word "and"
      .split(" ")
      .filter(Boolean);

    if (parts.length < 2) {
      return name.slice(0, 2).toUpperCase(); // Fallback to the first two chars if no valid parts
    }

    return parts
      .slice(0, 2)
      .map((part) => part.charAt(0).toUpperCase())
      .join("");
  };

  const getColor = (name, alpha) => {
    const initials = getInitials(name);
    if (initials.length < 2) return null; // Ensure we have enough characters for color generation
    return generateUniqueColor(initials[0], initials[1], null, alpha);
  };

  const initials = name === "Unknown" ? "" : getInitials(name);
  const bgColor = getColor(name, 0.75);
  const gradientBg = getColorGradient(initials);

  const getFormattedInitials = (initials) => {
    const cleanedInitials = initials?.replace(/[^A-Za-z]/g, "");
    return cleanedInitials || <Person />;
  };

  return (
    <Avatar
      src={avatarUrl}
      sx={{
        width: size,
        height: size,
        boxShadow: `0 6px 8px rgba(0, 0, 0, 0.0), 0 1px 6px ${bgColor}`,
        background: gradientBg ? gradientBg : theme.palette.grey[300],
        // bgcolor: bgColor ? bgColor : theme.palette.grey[300], // Fallback color if getColor returns null
        // color: theme.palette.getContrastText(bgColor || theme.palette.grey[300]),
      }}
    >
      {getFormattedInitials(initials)}
    </Avatar>
  );
};

export default ColorAvatar;
