import { Box, Container, Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import VisitReviewCarousel from "../../../components/reviews/VisitReviewCarousel";
import { Link } from "react-router-dom";
import React from "react";
import useTrackVisibility from "../../../hooks/useTrackVisibility";
import { REVIEW_COUNT } from "../../../services/variableServices";

const ReviewSection = ({pageName = ""}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ref = useTrackVisibility(() => {},
  `${pageName}/"Review section"`); // Pass the logMessage here

  return (
    <Box
    ref={ref}
      sx={{
        paddingTop: theme.spacing(4),
        backgroundColor: "white",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            // height: "100%",
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h3"}
            fontWeight="700"
            gutterBottom
            align="center"
          >
            Member Reviews
            <span style={{ color: theme.palette.primary.vibrant }}></span>
          </Typography>
          <Typography
            variant="body1"
            mb={4}
            align="left"
            sx={{ maxWidth: "600px", mx: "auto" }}
          >
            Curious what our members are saying about Linked Lives? This is a
            great place to read reviews for recent visits and get inspiration
            for tasks you can get help with.
          </Typography>
        </Box>
        <VisitReviewCarousel START_LIMIT={10} />
        <Box sx={{ mx: 1, mb: 4, mx: "auto" }}>
          <Button
            color="success"
            disableElevation
            component={Link} // use the Link component as the root node of the Button
            to="/reviews" // use 'to' instead of 'href' for react-router's Link
            variant="contained"
            size="large"
            // color="primary"
            sx={{
              width: { xs: "100%", sm: "250px" },
              textTransform: "none",
              height: "56px",
              mx: "auto",
            }}
          >
            Read all reviews
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewSection;
