import { Alert, alpha, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useHelpers } from "../../providers/HelpersProvider";

/** Must be called within Helpers Provider */

const ServiceStatusAlert = ({
  signupFeeStatus = undefined,
  city = "",
  showLoading = true,
  showWaitlist = false,
  initialStatus = { status: "active" },
  showIcon = true,
}) => {
  const { serviceStatus: providerStatus, loadingServiceStatus } = useHelpers() || {};

  const serviceStatus = providerStatus || initialStatus || {};

  console.log("ServiceStatusAlert - serviceStatus: ", serviceStatus);
  console.log(
    "ServiceStatusAlert - loadingServiceStatus: ",
    loadingServiceStatus
  );

  if (loadingServiceStatus && showLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height={48.02}
        sx={{
          borderRadius: "15px",
          mb: 2,
        }}
      />
    );
  }

  if (!serviceStatus) return null;

  const memberCity = city
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());

  let status = serviceStatus.status;
  // A member who has paid, never goes back to the waitlist
  if (!showWaitlist && status === "waitlist" && signupFeeStatus !== undefined) {
    status = "suspended";
  }

  switch (status) {
    case "waitlist":
      return (
        <Alert
          // icon={false}
          severity="error"
          sx={{
            borderRadius: "15px",
            mb: 2,
            boxShadow: (theme) =>
              `0px 4px 10px ${alpha(theme.palette.error.main, 0.2)}`,
            border: (theme) =>
              `1px solid ${alpha(theme.palette.error.main, 0.5)}`, // Border with alpha
          }}
        >
          We're not in {memberCity} quite yet. We will keep you in the loop once
          we launch!
        </Alert>
      );

    case "active":
      if (serviceStatus?.totalAvailableHours > 10) return; // no card for tons of availability

      if (
        serviceStatus?.serviceEnd === null ||
        serviceStatus?.serviceEnd > DateTime.now().plus({ months: 1 })
      ) {
        return (
          <Alert
            // icon={false}

            severity="success"
            sx={{
              borderRadius: "15px",
              mb: 2,
              boxShadow: (theme) =>
                `0px 4px 10px ${alpha(theme.palette.success.main, 0.2)}`,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.success.main, 0.5)}`, // Border with alpha
            }}
          >
            We're active in {memberCity}
            {serviceStatus?.totalAvailableHours <= 10 &&
              `, but our availability is limited. More will be added soon`}
            . You can book whenever you are ready!
          </Alert>
        );
      }

      if (serviceStatus.serviceEnd <= DateTime.now().plus({ months: 1 })) {
        return (
          <Alert
            // icon={false}

            severity="success"
            sx={{
              borderRadius: "15px",
              mb: 2,
              boxShadow: (theme) =>
                `0px 4px 10px ${alpha(theme.palette.success.main, 0.2)}`,
              border: (theme) =>
                `1px solid ${alpha(theme.palette.success.main, 0.5)}`, // Border with alpha
            }}
          >
            We're active in {memberCity}. Please note we will be temporarily
            paused starting {serviceStatus.serviceEnd.toFormat("MMMM d")}.
          </Alert>
        );
      }
      break;

    case "no_availability":
      return (
        <Alert
          // icon={false}

          severity="warning"
          sx={{
            borderRadius: "15px",
            mb: 2,
            boxShadow: (theme) =>
              `0px 4px 10px ${alpha(theme.palette.warning.main, 0.2)}`,
            border: (theme) =>
              `1px solid ${alpha(theme.palette.warning.main, 0.5)}`, // Border with alpha
          }}
        >
          We're active in {memberCity}, but all helpers are fully booked. We'll
          let you know once there is new availability.
        </Alert>
      );

    case "paused":
      return (
        <Alert
          // icon={false}

          severity="warning"
          sx={{
            borderRadius: "15px",
            mb: 2,
            boxShadow: (theme) =>
              `0px 4px 10px ${alpha(theme.palette.warning.main, 0.2)}`,
            border: (theme) =>
              `1px solid ${alpha(theme.palette.warning.main, 0.5)}`, // Border with alpha
          }}
        >
          Our services are temporarily paused in {memberCity}. We will be
          available again starting on{" "}
          {serviceStatus.serviceStart.toLocaleString(DateTime.DATE_MED)}. We
          will keep you updated!
        </Alert>
      );

    case "suspended":
      return (
        <Alert
          // icon={false}

          severity="error"
          sx={{
            borderRadius: "15px",
            mb: 2,
            boxShadow: (theme) =>
              `0px 4px 10px ${alpha(theme.palette.error.main, 0.2)}`,
            border: (theme) =>
              `1px solid ${alpha(theme.palette.error.main, 0.5)}`, // Border with alpha
          }}
        >
          Unfortunately, there are no active helpers in {memberCity} and so our
          service is temporarily paused. We're working hard to fix this and will
          keep you in the loop!
        </Alert>
      );

    default:
      return null;
  }
};

export default ServiceStatusAlert;
