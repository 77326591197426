import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";

const MessagesContext = createContext();

const INITIAL_FETCH = 10;
const PAGE_SIZE = 5;

export const MessagesProvider = ({ children }) => {
  const [allConversations, setAllConversations] = useState([]);
  const [unreadConversations, setUnreadConversations] = useState([]);
  const [hasMoreAll, setHasMoreAll] = useState(true); // ✅ Track separately for "All"
  const [hasMoreUnread, setHasMoreUnread] = useState(true); // ✅ Track separately for "Unread"
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterUnread, setFilterUnread] = useState(true);

  const mergeConversations = (prev, newConversations) => {
    const merged = [...prev, ...newConversations];

    // Deduplicate using a Map
    return Array.from(
      new Map(merged.map((conv) => [conv.id, conv])).values()
    ).sort(
      (a, b) =>
        (b.lastMessageTimestamp?.toMillis() || 0) -
        (a.lastMessageTimestamp?.toMillis() || 0)
    );
  };

  useEffect(() => {
    const conversationsRef = collection(db, "conversations");

    // Fetch all conversations
    const allQuery = query(
      conversationsRef,
      orderBy("lastMessageTimestamp", "desc"),
      limit(INITIAL_FETCH)
    );

    const unsubscribeAll = onSnapshot(allQuery, (snapshot) => {
      const convs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllConversations((prev) => mergeConversations(prev, convs));
      setHasMoreAll(snapshot.docs.length === INITIAL_FETCH); // ✅ Track "All"
    });

    // Fetch unread conversations
    const unreadQuery = query(
      conversationsRef,
      where("isRead", "==", false),
      orderBy("lastMessageTimestamp", "desc"),
      limit(INITIAL_FETCH)
    );

    const unsubscribeUnread = onSnapshot(unreadQuery, (snapshot) => {
      const convs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUnreadConversations((prev) => mergeConversations(prev, convs));
      setHasMoreUnread(snapshot.docs.length === INITIAL_FETCH); // ✅ Track "Unread"
    });

    return () => {
      unsubscribeAll();
      unsubscribeUnread();
    };
  }, []);

  const fetchMoreConversations = async () => {
    if (loadingMore) return;

    // Determine if there's more data to fetch based on the filter
    const hasMore = filterUnread ? hasMoreUnread : hasMoreAll;
    if (!hasMore) return;

    setLoadingMore(true);

    const lastTimestamp = (
      filterUnread
        ? unreadConversations[unreadConversations.length - 1]
        : allConversations[allConversations.length - 1]
    )?.lastMessageTimestamp;

    if (!lastTimestamp) {
      setLoadingMore(false);
      return;
    }

    const conversationsRef = collection(db, "conversations");
    const baseQuery = query(
      conversationsRef,
      orderBy("lastMessageTimestamp", "desc"),
      startAfter(lastTimestamp),
      limit(PAGE_SIZE)
    );

    const q = filterUnread
      ? query(baseQuery, where("isRead", "==", false))
      : baseQuery;

    try {
      const snapshot = await getDocs(q);
      const newConversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (filterUnread) {
        setUnreadConversations((prev) =>
          mergeConversations(prev, newConversations)
        );
        setHasMoreUnread(snapshot.docs.length === PAGE_SIZE); // ✅ Update "Unread" state
      } else {
        setAllConversations((prev) =>
          mergeConversations(prev, newConversations)
        );
        setHasMoreAll(snapshot.docs.length === PAGE_SIZE); // ✅ Update "All" state
      }
    } catch (error) {
      console.error("Error fetching more conversations:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const updateConversation = (conversationId, updatedData) => {
    setAllConversations((prev) =>
      prev.map((conv) =>
        conv.id === conversationId ? { ...conv, ...updatedData } : conv
      )
    );

    setUnreadConversations((prev) =>
      prev.map((conv) =>
        conv.id === conversationId ? { ...conv, ...updatedData } : conv
      )
    );
  };

  // Function to mark a conversation as read
  const markConversationAsRead = async (conversationId) => {
    try {
      const convRef = doc(db, "conversations", conversationId);
      await updateDoc(convRef, { isRead: true });

      // Update local state
      updateConversation(conversationId, { isRead: true });
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Function to mark a conversation as unread
  const markConversationAsUnread = async (conversationId) => {
    try {
      const convRef = doc(db, "conversations", conversationId);
      await updateDoc(convRef, { isRead: false });

      // Update local state
      updateConversation(conversationId, { isRead: false });
    } catch (error) {
      console.error("Error marking conversation as unread:", error);
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        allConversations,
        unreadConversations,
        filterUnread,
        setFilterUnread,
        fetchMoreConversations,
        loadingMore,
        updateConversation,
        markConversationAsRead, // ✅ Add this
        markConversationAsUnread, // ✅ Add this
        hasMore: filterUnread ? hasMoreUnread : hasMoreAll, // ✅ Expose the correct "hasMore"
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => useContext(MessagesContext);
