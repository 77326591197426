import React, { createContext, useCallback, useContext, useState } from "react";
import ResponsiveDialog from "../components/dialogs/ResponsiveDialog";
import ScheduleVisitAdmin from "../components/ScheduleVisitAdmin";
import { useAuth } from "./AuthProvider";
import { useMember } from "./MemberProvider";
import CloseConfirmation from "../components/dialogs/CloseConfirmation";

const ScheduleVisitContext = createContext();

export const ScheduleVisitProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const { memberData, memberPrivateData, memberAdminData, memberAccountData } =
    useMember();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState({});
  const [visitBooked, setVisitBooked] = useState(false);
  const [filters, setFilters] = useState({
    duration: 2,
    frequency: 0,
    employeeIds: [],
    numEmployees: "1",
  });

  const handleVisitBooked = () => {
    setVisitBooked(true)
  }

  const openScheduleVisit = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeScheduleVisit = useCallback(() => {
    setIsOpen(false);
    setVisitBooked(false)
  }, []);

  return (
    <ScheduleVisitContext.Provider
      value={{
        openScheduleVisit,
        setSelectedVisit,
        selectedVisit,
        filters,
        setFilters,
        visitBooked,
        handleVisitBooked,
      }}
    >
      {children}
      <ResponsiveDialog
        title={"Book a visit"}
        open={isOpen}
        onClose={closeScheduleVisit}
        width="800px"
        desktopAnchor={"right"}
        fullHeight={true}
        backgroundCloses={false}
        closeConfirmationDialog={
          !visitBooked &&   // Only show if `visitBooked` is false
          ((props) => (
            <CloseConfirmation
              modalTitle="Unsaved changes"
              modalBody="You have not booked a visit yet. Are you sure you want to close the scheduler?"
              confirmText="Yes, close"
              cancelText="Back to scheduler"
              {...props}  // Spread any additional props passed in dynamically
            />
          ))
        }
      >
        <ScheduleVisitAdmin
          selectedVisit={selectedVisit}
          filters={filters}
          handleClose={closeScheduleVisit}
          isOpen={isOpen}
          memberId={currentUser?.uid}
          memberData={memberData}
          memberPrivateData={memberPrivateData}
          memberAdminData={memberAdminData}
          memberAccountData={memberAccountData}
          onVisitBooked={handleVisitBooked}
        />
      </ResponsiveDialog>
    </ScheduleVisitContext.Provider>
  );
};

export const useScheduleVisit = () => useContext(ScheduleVisitContext);
