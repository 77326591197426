import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingUser from "../components/LoadingUser";
import useLogPageVisit from "../hooks/useLogPageVisit";

// Lazy-loaded dashboards
// Lazy-loaded dashboard wrappers
import LazyAdminDashboard from "../dashboard/admin/LazyAdminDashboard";
import LazyHelperDashboard from "../dashboard/employee/LazyHelperDashboard";
import LazyMemberDashboard from "../dashboard/member/LazyMemberDashboard";

const DashboardPage = () => {
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useLogPageVisit("/dashboard");

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Get the ID token result and update userClaims state
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            console.log("idTokenResult: ", idTokenResult);
            console.log("idTokenResult.claims: ", idTokenResult.claims);

            setUserClaims(idTokenResult.claims);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error retrieving token claims: ", error);
            setLoading(false);
          });
      } else {
        // User is not signed in
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle sign out errors here, such as showing a notification to the user
    }
  };

  // Render components based on user claims and loading state
  if (loading) {
    return <LoadingUser />;
  } else if (userClaims) {
    if (userClaims.role === "employee" && userClaims.isAdmin) {
      return <LazyAdminDashboard />;
    } else if (userClaims.role === "employee") {
      return <LazyHelperDashboard />;
    } else if (userClaims.role === "member") {
      return <LazyMemberDashboard />;
    } else {
      handleSignOut();
    }
  } else {
    navigate("/get-started");
  }
};

export default DashboardPage;
