import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import moment from "moment-timezone";

export const formatDate = (date, timeZoneId = "America/Los_Angeles") => {
  return moment(date).tz(timeZoneId).format("dddd, MMM Do");
};

export const formatTimeRange = (
  startDate,
  endDate,
  timeZoneId = "America/Los_Angeles",
  compactPeriod = false
) => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const start = moment(startDate).tz(timeZoneId);
  const end = moment(endDate).tz(timeZoneId);

  // Format start and end times without :00 if on the hour
  const startTime = start.format(start.minutes() === 0 ? "h" : "h:mm");
  const endTime = end.format(end.minutes() === 0 ? "h" : "h:mm");

  // Add am/pm in lowercase based on conditions
  const startPeriod = start.format("a"); // 'a' for lowercase am/pm
  const endPeriod = end.format("a");
  const timeRange =
    startPeriod === endPeriod && compactPeriod
      ? `${startTime} - ${endTime}${startPeriod}`
      : `${startTime}${startPeriod} - ${endTime}${endPeriod}`;

  const displayTimeZone =
    browserTimeZone !== timeZoneId
      ? ` ${moment.tz(timeZoneId).zoneAbbr()}`
      : "";

  return `${timeRange}${displayTimeZone}`;
};

export const formatAddress = (address) => {
  let formattedAddress = address.line1; // Start with line 1, which presumably always exists

  if (address.line2) {
    formattedAddress += `, ${address.line2}`; // Add line 2 if it exists
  }

  // Continue adding components with assured existence, separated by commas
  formattedAddress += `, ${address.city}`;
  formattedAddress += `, ${address.state}`;

  // Assuming zipCode always exists as well
  formattedAddress += ` ${address.zipCode}`;

  return formattedAddress;
};

// Function to format the timestamp based on the specified criteria
export const formatTimestamp = (timestamp) => {
  const now = moment();
  const logDate = moment(timestamp.toDate()); // Convert Firestore timestamp to moment object

  // Check the difference in days
  const diffDays = now.diff(logDate, "days");

  if (diffDays < 7) {
    return logDate.format("ddd h:mm A"); // e.g., Thu 3:45 PM
  } else if (diffDays < 365) {
    return logDate.format("MMM D h:mm A"); // e.g., Oct 17 3:45 PM
  } else {
    return logDate.format("MMM D, YYYY"); // e.g., Oct 17, 2022
  }
};

// drop the :00 for a time string
export const formatTime = (time, timeZoneId) => {
  const formattedTime = moment(time.toDate()).tz(timeZoneId).format("h:mma");
  return formattedTime.replace(":00", "");
};

export const formatDateRange = (eventDays) => {
  const dates = Object.values(eventDays).map((day) => ({
    start: moment(day.startTime.toDate()),
    end: moment(day.endTime.toDate()),
  }));

  if (dates.length === 0) return "Dates not set";

  // Find the earliest start date and the latest end date
  const earliestStart = moment.min(dates.map((date) => date.start));
  const latestEnd = moment.max(dates.map((date) => date.end));

  if (earliestStart.isSame(latestEnd, "day")) {
    return earliestStart.format("MMMM Do"); // Single day event
  } else if (earliestStart.isSame(latestEnd, "month")) {
    // If start and end dates are in the same month
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format("Do")}`;
  } else {
    // If start and end dates are in different months
    return `${earliestStart.format("MMMM Do")} - ${latestEnd.format(
      "MMMM Do"
    )}`;
  }
};

export const formatTimeRangeCompact = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? " a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""} a`
  );

  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const formatTimeRangeCompactListItem = ({ start, end, timeZoneId }) => {
  const startTime = moment(start.toDate()).tz(timeZoneId);
  const endTime = moment(end.toDate()).tz(timeZoneId);

  const isSameAmPm = startTime.format("a") === endTime.format("a");

  // Format start time: Include 'a' if AM/PM differs or if minutes are not zero
  const startTimeFormatted = startTime.format(
    `h${startTime.minute() !== 0 ? ":mm" : ""}${!isSameAmPm ? "a" : ""}`
  );

  // Format end time: Always include 'a', remove ':00' if on the hour
  const endTimeFormatted = endTime.format(
    `h${endTime.minute() !== 0 ? ":mm" : ""}a`
  );

  return `${startTimeFormatted} to ${endTimeFormatted}`;
};

export function calculateAge(birthMonth) {

  const birthDate = DateTime.fromISO(birthMonth + "-01");
  const diff = DateTime.now().diff(birthDate, ["years", "months"]).toObject();
  const age = Math.floor(diff.years - (diff.months < 0 ? 1 : 0));

  if (age > 40) {
    const decade = Math.floor(age / 10) * 10; // Find the starting year of the decade
    const yearWithinDecade = age % 10; // Find how many years into the decade

    if (yearWithinDecade < 4) return `early ${decade}'s`;
    else if (yearWithinDecade < 7) return `mid ${decade}'s`;
    else return `late ${decade}'s`;
  }

  return age;
}

export const formatMessageTime = (firebaseTimestamp) => {
  // Check if firebaseTimestamp is valid and can be converted to a Date
  if (!firebaseTimestamp || typeof firebaseTimestamp.toDate !== "function") {
    return "Invalid date"; // or any other fallback value you prefer
  }

  const now = DateTime.now();
  const messageDate = DateTime.fromJSDate(firebaseTimestamp.toDate());

  if (messageDate.toFormat("yyyy-MM-dd") === now.toFormat("yyyy-MM-dd")) {
    // Message is from today
    return messageDate.toFormat("h:mm a");
  } else if (
    messageDate.toFormat("yyyy-MM-dd") ===
    now.minus({ days: 1 }).toFormat("yyyy-MM-dd")
  ) {
    // Message is from yesterday
    return `Yesterday ${messageDate.toFormat("h:mm a")}`;
  } else if (
    now.diff(messageDate, "days").days < 7 &&
    messageDate.weekNumber === now.weekNumber
  ) {
    // Message is from the past week
    return `${messageDate.toFormat("cccc h:mm a")}`;
  } else if (messageDate.year === now.year) {
    // Message is from earlier in the current year
    return messageDate.toFormat("LLLL d, h:mm a");
  } else {
    // Message is from a previous year
    return messageDate.toFormat("LLLL d, yyyy h:mm a");
  }
};

/**
 * Formats a timestamp based on specific rules:
 * - "9:23 AM" if today
 * - "Yesterday" if yesterday
 * - "Tuesday" if within the past week
 * - "10/12/13" if older than 1 week
 */
export const formatListItemDate = (timestamp) => {
  // Convert Firestore Timestamp or raw timestamp to a JavaScript Date
  const date =
    timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);

  const now = DateTime.local();
  const messageDate = DateTime.fromJSDate(date);

  if (messageDate.hasSame(now, "day")) {
    return messageDate.toFormat("h:mm a"); // e.g., "9:23 AM"
  }

  if (messageDate.hasSame(now.minus({ days: 1 }), "day")) {
    return "Yesterday";
  }

  if (messageDate >= now.minus({ days: 7 })) {
    return messageDate.toFormat("cccc"); // e.g., "Tuesday"
  }

  return messageDate.toFormat("MM/dd/yy"); // e.g., "10/12/13"
};

export const formatVisitDate = (timestamp) => {
  if (!timestamp) return "";
  const date =
    timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
  const now = DateTime.local();
  const visitDate = DateTime.fromJSDate(date);

  // More than a year in the past or future
  if (Math.abs(visitDate.diff(now, "years").years) >= 1) {
    return visitDate.toFormat("MMM yyyy"); // e.g., "Jan 2025"
  }

  if (visitDate.hasSame(now, "day")) {
    return "today";
  }
  if (visitDate.hasSame(now.plus({ days: 1 }), "day")) {
    return "tomorrow";
  }
  if (visitDate.hasSame(now.minus({ days: 1 }), "day")) {
    return "yesterday";
  }
  if (
    visitDate >= now.minus({ days: 6 }) &&
    visitDate <= now.plus({ days: 6 })
  ) {
    // Within the next 6 days
    return `on ${visitDate.toFormat("cccc")}`; // e.g., "on Wednesday"
  }

  // Default for other dates within a year
  return `on ${visitDate.toFormat("MMM d")}`; // e.g., "on Wed Dec 12"
};

export const formatMessagesTimestamp = (timestamp, isMobile) => {
  if (!timestamp?.seconds) return "";

  const date = DateTime.fromSeconds(timestamp.seconds);
  const now = DateTime.local();
  const timeFormat = "h:mm a"; // "1:13 PM"

  if (date.hasSame(now, "day")) {
    return date.toFormat(timeFormat); // Only time if today
  } else if (date >= now.minus({ days: 6 })) {
    const dayFormat = isMobile ? "EEE" : "EEEE"; // "Tue" or "Tuesday"
    return `${date.toFormat(dayFormat)} ${date.toFormat(timeFormat)}`;
  } else if (date.hasSame(now, "year")) {
    return date.toFormat(`MMM d ${timeFormat}`); // "Jan 12 1:13 PM"
  } else {
    return date.toFormat(`MMM d, yyyy ${timeFormat}`); // "Jan 12, 2024 1:13 PM"
  }
};