import { ArrowBackIosNewRounded, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrawer } from "../../providers/DrawerProvider";
import Logo from "../../svgs/Logo";

export const renderDrawerHeader = (
  title,
  handleClose,
  isMobile,
  anchor,
  showAction,
  showLogo
) => (
  <Box
    sx={{
      position: "relative", // Added to position children absolutely within this Box.
      display: "flex",
      alignItems: "center", // Vertically aligns items in the center.
      padding: 2,
      justifyContent: showAction || showLogo ? "space-between" : "center",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      height: "80px",
      maxHeight: "80px",
      minHeight: "80px",
      boxSizing: "border-box",
    }}
  >
    {showAction && (
      <IconButton onClick={handleClose}>
        {!isMobile && <Close />}
        {isMobile && anchor === "bottom" && <Close />}
        {isMobile && anchor === "right" && <ArrowBackIosNewRounded />}
      </IconButton>
    )}

    {showLogo && (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          zIndex: "2000",
        }}
      >
        <Box width={150}>
          <Logo color="rgba(0, 0, 0, 0.87)" />
        </Box>
      </Box>
    )}

    {/* Title container to help center the title text */}
    <Box
      sx={{
        mx: showLogo ? 0 : 1, // Adjust margin based on logo presence
        flexGrow: showLogo ? 1 : 0, // If logo is present, allow title to grow
        display: "flex",
        justifyContent: showLogo ? "flex-end" : "center",
      }}
    >
      <Typography variant="h6">{title}</Typography>
    </Box>

    {showAction && (
      <IconButton style={{ visibility: "hidden", pointerEvents: "none" }}>
        <Close />
      </IconButton>
    )}

    {/* Make puller bar */}
    {isMobile && anchor === "bottom" && showAction && (
      <Box
        sx={{
          position: "absolute",
          top: 8,
          left: "50%",
          transform: "translateX(-50%)",
          width: 50,
          height: 5,
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          borderRadius: 2.5,
        }}
      />
    )}
  </Box>
);

const DrawerWrapper = ({
  drawer,
  index,
  handleDrawerExited,
  isMobile,
  drawers,
}) => {
  const {
    component: Component = null,
    props = {},
    onClose = () => {},
  } = drawer || {};

  const firstNonNullIndex = drawers.findIndex((drawer) => drawer !== null);

  const {
    hideBackdrop = !isMobile && !(index === firstNonNullIndex),
    anchor = "right",
    title = "Details",
    height,
    zIndex,
    width = { xs: "100%", sm: "800px" },
    showAction = true,
    showLogo = false,
    disableSwipeToClose = false, // Add this property to control swipe-to-close
    showDefaultHeader = true,
    modalType = null,
    showBackdrop = true,
  } = props;

  console.log("DrawerWrapper - index: ", index);

  console.log("DrawerWrapper - zIndex: ", zIndex);

  const [open, setOpen] = useState(false);

  const borderRadius = anchor === "bottom" ? "15px" : "0px";
  const mobileHeight = anchor === "bottom" ? "98vh" : "100vh";

  useEffect(() => {
    // Set open to true after the component mounts to trigger the slide-in animation
    setOpen(true);
  }, []);

  if (!Component) return null;

  const handleClose = (value) => {
    if (onClose) onClose(value); // Call the onClose callback with the provided value

    if (modalType === "dialog") {
      handleDrawerExited(index);
    }

    // Drawer doesn't accept onTransitionEnd prop, so we manually close out after animation ends.
    if (disableSwipeToClose) {
      // Delay the execution of handleDrawerExited by 300ms
      setTimeout(() => {
        handleDrawerExited(index);
      }, 300);
    }

    setOpen(false);
  };

  const handleTransitionEnd = () => {
    if (!open) {
      handleDrawerExited(index);
    }
  };

  const renderDialog = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      hideBackdrop={!showBackdrop}
      PaperProps={{
        sx: {
          zIndex: zIndex && zIndex,
          position: "relative", // Set position to relative
          borderRadius: "15px",
          // width: width,
          // maxWidth: width,
          maxHeight: `calc(100% - 64px)`,
        },
      }}
    >
      {/* Dialog content */}
      {showDefaultHeader && renderDrawerHeader(title)}
      <Divider />

      <Component
        {...props}
        onClose={handleClose}
        handleBack={handleClose}
        handleNext={handleClose}
      />
    </Dialog>
  );

  if (modalType === "dialog") {
    return renderDialog();
  }

  const DrawerComponent = disableSwipeToClose ? Drawer : SwipeableDrawer; // Choose component based on input

  return (
    <DrawerComponent
      anchor={anchor}
      open={open}
      onClose={handleClose}
      disableSwipeToOpen={true}
      onTransitionEnd={handleTransitionEnd}
      sx={{
        zIndex: zIndex && zIndex, // Setting the zIndex for the MuiDrawer-root
      }}
      // hideBackdrop={hideBackdrop} - we can't use this because then it disables click listeners on the backdrop. We set it to transparent intstead
      slotProps={{
        backdrop: {
          style: {
            backgroundColor:
              !isMobile && hideBackdrop ? "transparent" : undefined,
          },
        },
      }}
      PaperProps={{
        sx: {
          className: "hideScrollbar",
          borderTopLeftRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-left corner
          borderTopRightRadius: { xs: borderRadius, sm: "0px" }, // Rounded top-right corner
          maxHeight: mobileHeight,
          height,
          width,
          zIndex: zIndex && { zIndex },
          boxShadow:
            "0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)",
        },
      }}
    >
      {showDefaultHeader &&
        renderDrawerHeader(
          title,
          handleClose,
          isMobile,
          anchor,
          showAction,
          showLogo
        )}
      <Component
        {...props}
        onClose={handleClose}
        handleBack={handleClose}
        handleNext={handleClose}
      />
    </DrawerComponent>
  );
};

const DrawerContainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { drawers, handleDrawerExited } = useDrawer();

  console.log("drawers: ", drawers);

  return (
    <div>
      {/* Render all active drawers */}
      {drawers.map((drawer, index) => (
        <DrawerWrapper
          drawers={drawers}
          key={index}
          drawer={drawer}
          index={index}
          handleDrawerExited={handleDrawerExited}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

export default DrawerContainer;
