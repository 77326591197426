import { Box, Typography, useTheme, keyframes } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useSession } from "../../../providers/SessionProvider";
import { MEMBER_COUNT } from "../../../services/variableServices";
import PricingCards from "./PricingCards";

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PricingCardSection = () => {
  const { locationData } = useSession();
  const zipCode = locationData?.postal || null;
  const city = locationData?.city || null;
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative", width: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          justifyContent: "center",
          pt: { xs: 4, sm: 10 },
          pb: { xs: 4, sm: 4 },
          px: 1,
          alignItems: "center",
          animation: `${fadeInDown} 0.6s ease-out forwards`,
          opacity: 0,
        }}
      >
        <Typography
          align="center"
          variant="h3"
          sx={{
            display: "inline-block",
            textAlign: "center",
          }}
        >
          <Box
            component="span"
            sx={{
              display: { xs: "block", sm: "inline" }, // Force a break on mobile
            }}
          >
            Choose
          </Box>{" "}
          Your Plan
        </Typography>

        <Typography align="left" sx={{ maxWidth: "680px", mt: 1 }}>
          We offer transparent and simple pricing, that's flexible for your
          budget. Join over {Math.floor(MEMBER_COUNT / 10) * 10}+{" "}
          <Link
            component="span"
            to="/reviews"
            style={{
              fontWeight: "600",
              color: theme.palette.primary.main,
            }}
          >
            happy members
          </Link>{" "}
          who count on us for their household help. Consistent, reliable, and
          here for you.
        </Typography>
        <div style={{ height: "12px" }} />

        <PricingCards dense={true} zipCode={zipCode} city={city} />
        <Typography variant="body1"></Typography>
      </Box>
    </Box>
  );
};

export default PricingCardSection;
