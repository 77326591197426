import { Check, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  List,
  ListItem,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { REVIEW_COUNT } from "../../../services/variableServices";

// Define the plan details using numeric values for fees and rates
export const PRICING_PLANS = [
  {
    tier: 0,
    monthlyString: "zero monthly dues",
    chip: "Basic",
    color: "black",
    membershipFee: 0, // Numeric value for membership fee
    hourlyRate: 40, // Numeric value for hourly rate
    benefits: [
      // "Join hundreds of happy members",
      // "Lock in your lifetime hourly rate",
      "Choose your helper",
      "1-hour visit minimum",
      "Flat-rate regardless of tasks",
      "BBB accredited help on-demand",
      // "Earn a free hour per referral",
      // "Schedule 3-4 days in advance",
      // "Hard working and efficient",
      "Our money back guarantee",
      // "End anytime with no fees",
      // "$50 signup fee for lifetime access",
      // "Excellent customer service",
      // "Mix and match any tasks to fill the visit",
      // "Senior-friendly online scheduling",
      // "Schedule recurring visits",
      // "Same day visits",
      // ... more benefits
    ],
  },
  {
    tier: 1,
    chip: "Premium",
    color: "white",
    monthlyString: "plus $25 monthly dues",
    membershipFee: 25, // Numeric value for membership fee
    hourlyRate: 35, // Numeric value for hourly rate
    benefits: [
      "Everything from basic, plus...",
      "Discounted hourly rate",
      "Personal support line",
      "Priority booking",
      "No contracts, cancel anytime",
      // "Access to referral network",
      // "Same day visits",
      // ... more benefits
    ],
  },
  {
    tier: 2,
    chip: "Premium",
    color: "white",
    monthlyString: "plus $50 monthly dues",
    membershipFee: 50, // Numeric value for membership fee
    hourlyRate: 25, // Numeric value for hourly rate
    benefits: [
      "Everything from basic, plus...",
      "Discounted hourly rate",
      "Personal support line",
      "Priority booking",
      "No contracts, cancel anytime",
      // "Access to referral network",
      // "Same day visits",
      // ... more benefits
    ],
  },
];

function PricingCards({ dense = false, showSignupFee = true }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [reviewCount, setReviewCount] = useState(REVIEW_COUNT);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchReviewCount = async () => {
    try {
      const reviewQuery = query(
        collection(db, "visits"),
        where("rating", "==", 5)
      );
      const snapshot = await getCountFromServer(reviewQuery);
      setReviewCount(snapshot.data().count);
    } catch (error) {
      console.error("Error fetching review count:", error);
      // Optionally handle the error (e.g., set a default value or display a message)
    }
  };

  useEffect(() => {
    fetchReviewCount();
  }, []);

  const renderBenefits = (benefits, isPriority) => {
    return (
      <List dense={true}>
        {benefits.map((benefit, idx) => (
          <ListItem key={idx} sx={{ px: 0, alignItems: "flex-start" }}>
            <Check
              color={isPriority ? "vibrant" : "primary"}
              sx={{ mr: 1, opacity: 1 }}
            />
            {benefit === "Join hundreds of happy members" ? (
              <Typography variant="body1">
                Join hundreds of{" "}
                <Link
                  component="span"
                  to="/reviews"
                  style={{
                    fontWeight: "600",
                    color: theme.palette.primary.main,
                  }}
                >
                  happy members
                </Link>
              </Typography>
            ) : benefit === "Lock in your lifetime hourly rate" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">{benefit}</Typography>
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 0.5 }}
                >
                  {["2023 rate: $35/hr", "2022 rate: $30/hr"].map(
                    (rate, index) => (
                      <Chip
                        size="small"
                        sx={{ borderRadius: "5px" }}
                        key={index}
                        label={rate}
                        variant="filled"
                      />
                    )
                  )}
                </Box>
              </Box>
            ) : (
              <Typography variant="body1">{benefit}</Typography>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  // Remove tier 1
  const filteredPlans = PRICING_PLANS.filter((plan) => plan.tier !== 1);

  return (
    <Box sx={{ maxWidth: "1000px", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: { xs: 1, sm: 2 },
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {filteredPlans.map((plan, idx) => {
          const isPriority = plan.tier !== 0;
          return (
            <Box
              key={idx}
              sx={{
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  p: { xs: 3, sm: 4 },
                  boxShadow:
                    plan.tier === 0
                      ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                      : `
                        0px 0px 4px rgba(0, 192, 255, 0.3),     /* Bright close */
                        0px 6px 16px rgba(0, 192, 255, 0.3),    /* Less bright mid */
                        0px 12px 32px rgba(0, 0, 0, 0.15)       /* Dark wide */
                      `,
                  backgroundColor:
                    plan.tier === 0 ? "white" : theme.palette.primary.darkest,
                  color: plan.tier === 0 ? "unset" : "white",
                  transition:
                    "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for transform and shadow
                  // "&:hover": {
                  // transform: "translateY(-4px)", // Slightly raise the card
                  // boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)", // Elegant drop shadow
                  // },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Chip title block */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Chip
                      size="large"
                      variant="outlined"
                      color="success"
                      label={"Most popular"}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        borderWidth: "2px",
                        borderRadius: "5px",
                        backgroundColor: "#C7F7C7",
                        borderColor: "#51BC51",
                        color: "#136C13",
                        visibility: plan.tier === 0 ? "hidden" : "visible",
                      }}
                    />
                    <Typography variant="h4">{plan.chip}</Typography>
                  </Box>

                  {/* Review block */}
                  {plan.tier !== 0 && (
                    <Box
                      onClick={() => navigate("/reviews")}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: 0,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <Rating
                        value={5}
                        readOnly
                        precision={0.5}
                        sx={{
                          "& .MuiRating-icon": {
                            fontSize: "24px", // Smaller icon size if needed
                            margin: "0px -1.5px", // Reduce horizontal spacing
                          },
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        {reviewCount} reviews
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "column" },
                    justifyContent: "space-between",
                  }}
                >
                  <Divider
                    sx={{
                      mt: 2,
                      mb: 1,
                      borderColor: isPriority
                        ? "rgba(255, 255, 255, 0.12)"
                        : "rgba(0, 0, 0, 0.12)",
                    }}
                  />

                  {renderBenefits(plan.benefits, plan.tier !== 0)}
                  {showSignupFee &&
                    (plan.tier === 0 ? (
                      <ListItem
                        key={idx}
                        sx={{
                          px: 0,
                          pt: 0,
                          mt: "-2px",
                          alignItems: "flex-start",
                        }}
                      >
                        <Check
                          color={isPriority ? "vibrant" : "primary"}
                          sx={{ mr: 1, opacity: 1 }}
                        />
                        <Typography>
                          $50 signup fee for lifetime access
                        </Typography>
                      </ListItem>
                    ) : (
                      !isMobile && (
                        <ListItem
                          key={idx}
                          sx={{
                            px: 0,
                            pt: 0,
                            mt: "-2px",
                            alignItems: "flex-start",
                          }}
                        >
                          <Check
                            color={isPriority ? "vibrant" : "primary"}
                            sx={{ mr: 1, opacity: 0 }}
                          />
                          <Typography> </Typography>
                        </ListItem>
                      )
                    ))}
                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                      borderColor: isPriority
                        ? "rgba(255, 255, 255, 0.12)"
                        : "rgba(0, 0, 0, 0.12)",
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "column" },
                        justifyContent: "flex-start", //"space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontSize="24px"
                        fontWeight="800"
                        align="center"
                        sx={{ mt: dense ? 1 : 2 }}
                      >
                        ${plan.hourlyRate}
                        <Typography
                          component="span"
                          fontSize="24px"
                          fontWeight="400"
                          sx={{ opacity: 0.65 }}
                        >
                          {" "}
                          / hour
                        </Typography>
                      </Typography>

                      <Typography
                        variant="body1"
                        fontSize="16px"
                        align="center"
                        sx={{ opacity: 0.75 }}
                      >
                        {plan.monthlyString}
                      </Typography>
                    </Box>
                    <Button
                      disableElevation
                      onClick={() => navigate("/get-started")}
                      variant="contained"
                      color={"lightGray"}
                      sx={{
                        textTransform: "none",
                        borderRadius: "5px",
                        color: "revert",
                      }}
                      endIcon={<KeyboardArrowRight />}
                    >
                      Start now
                    </Button>
                  </Box>
                </Box>
                {/* {renderButton(plan)} */}
              </Card>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default PricingCards;
